/* Блок для вывода сообщений */
import React from 'react';
import './style.css';

class NotifyBlock extends React.Component {
	render() {
		let { children, className, type } = this.props
		return (
			<div className={`NotifyBlock ${type} ${className}`}>
				{children}
			</div>
		);
	}
}

export default NotifyBlock;