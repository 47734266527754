//Этот файл сгенерирован автоматически
import { apiRequest } from '../../vkApiRequest';


//undefined
//user,group
export function groupsAddAddress(access_token, params = {}, successCallback = () => {}, errorCallback = () => {}) {
	apiRequest("groups.addAddress",{ ...params
	}, access_token, (response) => {
		successCallback(response)
	},(error) => {
		errorCallback(error)
	})
}

//undefined
//user,group
export function groupsAddCallbackServer(access_token, params = {}, successCallback = () => {}, errorCallback = () => {}) {
	apiRequest("groups.addCallbackServer",{ ...params
	}, access_token, (response) => {
		successCallback(response)
	},(error) => {
		errorCallback(error)
	})
}

//Allows to add a link to the community.
//user
export function groupsAddLink(access_token, params = {}, successCallback = () => {}, errorCallback = () => {}) {
	apiRequest("groups.addLink",{ ...params
	}, access_token, (response) => {
		successCallback(response)
	},(error) => {
		errorCallback(error)
	})
}

//Allows to approve join request to the community.
//user
export function groupsApproveRequest(access_token, params = {}, successCallback = () => {}, errorCallback = () => {}) {
	apiRequest("groups.approveRequest",{ ...params
	}, access_token, (response) => {
		successCallback(response)
	},(error) => {
		errorCallback(error)
	})
}

//undefined
//user
export function groupsBan(access_token, params = {}, successCallback = () => {}, errorCallback = () => {}) {
	apiRequest("groups.ban",{ ...params
	}, access_token, (response) => {
		successCallback(response)
	},(error) => {
		errorCallback(error)
	})
}

//Creates a new community.
//user
export function groupsCreate(access_token, params = {}, successCallback = () => {}, errorCallback = () => {}) {
	apiRequest("groups.create",{ ...params
	}, access_token, (response) => {
		successCallback(response)
	},(error) => {
		errorCallback(error)
	})
}

//undefined
//user,group
export function groupsDeleteCallbackServer(access_token, params = {}, successCallback = () => {}, errorCallback = () => {}) {
	apiRequest("groups.deleteCallbackServer",{ ...params
	}, access_token, (response) => {
		successCallback(response)
	},(error) => {
		errorCallback(error)
	})
}

//Allows to delete a link from the community.
//user
export function groupsDeleteLink(access_token, params = {}, successCallback = () => {}, errorCallback = () => {}) {
	apiRequest("groups.deleteLink",{ ...params
	}, access_token, (response) => {
		successCallback(response)
	},(error) => {
		errorCallback(error)
	})
}

//undefined
//user,group
export function groupsDisableOnline(access_token, params = {}, successCallback = () => {}, errorCallback = () => {}) {
	apiRequest("groups.disableOnline",{ ...params
	}, access_token, (response) => {
		successCallback(response)
	},(error) => {
		errorCallback(error)
	})
}

//Edits a community.
//user
export function groupsEdit(access_token, params = {}, successCallback = () => {}, errorCallback = () => {}) {
	apiRequest("groups.edit",{ ...params
	}, access_token, (response) => {
		successCallback(response)
	},(error) => {
		errorCallback(error)
	})
}

//undefined
//user,group
export function groupsEditAddress(access_token, params = {}, successCallback = () => {}, errorCallback = () => {}) {
	apiRequest("groups.editAddress",{ ...params
	}, access_token, (response) => {
		successCallback(response)
	},(error) => {
		errorCallback(error)
	})
}

//undefined
//user,group
export function groupsEditCallbackServer(access_token, params = {}, successCallback = () => {}, errorCallback = () => {}) {
	apiRequest("groups.editCallbackServer",{ ...params
	}, access_token, (response) => {
		successCallback(response)
	},(error) => {
		errorCallback(error)
	})
}

//Allows to edit a link in the community.
//user
export function groupsEditLink(access_token, params = {}, successCallback = () => {}, errorCallback = () => {}) {
	apiRequest("groups.editLink",{ ...params
	}, access_token, (response) => {
		successCallback(response)
	},(error) => {
		errorCallback(error)
	})
}

//Allows to add, remove or edit the community manager.
//user
export function groupsEditManager(access_token, params = {}, successCallback = () => {}, errorCallback = () => {}) {
	apiRequest("groups.editManager",{ ...params
	}, access_token, (response) => {
		successCallback(response)
	},(error) => {
		errorCallback(error)
	})
}

//undefined
//user,group
export function groupsEnableOnline(access_token, params = {}, successCallback = () => {}, errorCallback = () => {}) {
	apiRequest("groups.enableOnline",{ ...params
	}, access_token, (response) => {
		successCallback(response)
	},(error) => {
		errorCallback(error)
	})
}

//Returns a list of the communities to which a user belongs.
//user
export function groupsGet(access_token, params = {}, successCallback = () => {}, errorCallback = () => {}) {
	apiRequest("groups.get",{ ...params
	}, access_token, (response) => {
		successCallback(response)
	},(error) => {
		errorCallback(error)
	})
}

//Returns a list of community addresses.
//user,service
export function groupsGetAddresses(access_token, params = {}, successCallback = () => {}, errorCallback = () => {}) {
	apiRequest("groups.getAddresses",{ ...params
	}, access_token, (response) => {
		successCallback(response)
	},(error) => {
		errorCallback(error)
	})
}

//Returns a list of users on a community blacklist.
//user,group
export function groupsGetBanned(access_token, params = {}, successCallback = () => {}, errorCallback = () => {}) {
	apiRequest("groups.getBanned",{ ...params
	}, access_token, (response) => {
		successCallback(response)
	},(error) => {
		errorCallback(error)
	})
}

//Returns information about communities by their IDs.
//user,group,service
export function groupsGetById(access_token, params = {}, successCallback = () => {}, errorCallback = () => {}) {
	apiRequest("groups.getById",{ ...params
	}, access_token, (response) => {
		successCallback(response)
	},(error) => {
		errorCallback(error)
	})
}

//Returns Callback API confirmation code for the community.
//user,group
export function groupsGetCallbackConfirmationCode(access_token, params = {}, successCallback = () => {}, errorCallback = () => {}) {
	apiRequest("groups.getCallbackConfirmationCode",{ ...params
	}, access_token, (response) => {
		successCallback(response)
	},(error) => {
		errorCallback(error)
	})
}

//undefined
//user,group
export function groupsGetCallbackServers(access_token, params = {}, successCallback = () => {}, errorCallback = () => {}) {
	apiRequest("groups.getCallbackServers",{ ...params
	}, access_token, (response) => {
		successCallback(response)
	},(error) => {
		errorCallback(error)
	})
}

//Returns [vk.com/dev/callback_api|Callback API] notifications settings.
//user,group
export function groupsGetCallbackSettings(access_token, params = {}, successCallback = () => {}, errorCallback = () => {}) {
	apiRequest("groups.getCallbackSettings",{ ...params
	}, access_token, (response) => {
		successCallback(response)
	},(error) => {
		errorCallback(error)
	})
}

//Returns communities list for a catalog category.
//user
export function groupsGetCatalog(access_token, params = {}, successCallback = () => {}, errorCallback = () => {}) {
	apiRequest("groups.getCatalog",{ ...params
	}, access_token, (response) => {
		successCallback(response)
	},(error) => {
		errorCallback(error)
	})
}

//Returns categories list for communities catalog
//user
export function groupsGetCatalogInfo(access_token, params = {}, successCallback = () => {}, errorCallback = () => {}) {
	apiRequest("groups.getCatalogInfo",{ ...params
	}, access_token, (response) => {
		successCallback(response)
	},(error) => {
		errorCallback(error)
	})
}

//Returns invited users list of a community
//user
export function groupsGetInvitedUsers(access_token, params = {}, successCallback = () => {}, errorCallback = () => {}) {
	apiRequest("groups.getInvitedUsers",{ ...params
	}, access_token, (response) => {
		successCallback(response)
	},(error) => {
		errorCallback(error)
	})
}

//Returns a list of invitations to join communities and events.
//user
export function groupsGetInvites(access_token, params = {}, successCallback = () => {}, errorCallback = () => {}) {
	apiRequest("groups.getInvites",{ ...params
	}, access_token, (response) => {
		successCallback(response)
	},(error) => {
		errorCallback(error)
	})
}

//Returns the data needed to query a Long Poll server for events
//user,group
export function groupsGetLongPollServer(access_token, params = {}, successCallback = () => {}, errorCallback = () => {}) {
	apiRequest("groups.getLongPollServer",{ ...params
	}, access_token, (response) => {
		successCallback(response)
	},(error) => {
		errorCallback(error)
	})
}

//Returns Long Poll notification settings
//user,group
export function groupsGetLongPollSettings(access_token, params = {}, successCallback = () => {}, errorCallback = () => {}) {
	apiRequest("groups.getLongPollSettings",{ ...params
	}, access_token, (response) => {
		successCallback(response)
	},(error) => {
		errorCallback(error)
	})
}

//Returns a list of community members.
//user,group,service
export function groupsGetMembers(access_token, params = {}, successCallback = () => {}, errorCallback = () => {}) {
	apiRequest("groups.getMembers",{ ...params
	}, access_token, (response) => {
		successCallback(response)
	},(error) => {
		errorCallback(error)
	})
}

//Returns a list of requests to the community.
//user
export function groupsGetRequests(access_token, params = {}, successCallback = () => {}, errorCallback = () => {}) {
	apiRequest("groups.getRequests",{ ...params
	}, access_token, (response) => {
		successCallback(response)
	},(error) => {
		errorCallback(error)
	})
}

//Returns community settings.
//user
export function groupsGetSettings(access_token, params = {}, successCallback = () => {}, errorCallback = () => {}) {
	apiRequest("groups.getSettings",{ ...params
	}, access_token, (response) => {
		successCallback(response)
	},(error) => {
		errorCallback(error)
	})
}

//undefined
//group
export function groupsGetTokenPermissions(access_token, params = {}, successCallback = () => {}, errorCallback = () => {}) {
	apiRequest("groups.getTokenPermissions",{ ...params
	}, access_token, (response) => {
		successCallback(response)
	},(error) => {
		errorCallback(error)
	})
}

//Allows to invite friends to the community.
//user
export function groupsInvite(access_token, params = {}, successCallback = () => {}, errorCallback = () => {}) {
	apiRequest("groups.invite",{ ...params
	}, access_token, (response) => {
		successCallback(response)
	},(error) => {
		errorCallback(error)
	})
}

//Returns information specifying whether a user is a member of a community.
//user,group,service
export function groupsIsMember(access_token, params = {}, successCallback = () => {}, errorCallback = () => {}) {
	apiRequest("groups.isMember",{ ...params
	}, access_token, (response) => {
		successCallback(response)
	},(error) => {
		errorCallback(error)
	})
}

//With this method you can join the group or public page, and also confirm your participation in an event.
//user
export function groupsJoin(access_token, params = {}, successCallback = () => {}, errorCallback = () => {}) {
	apiRequest("groups.join",{ ...params
	}, access_token, (response) => {
		successCallback(response)
	},(error) => {
		errorCallback(error)
	})
}

//With this method you can leave a group, public page, or event.
//user
export function groupsLeave(access_token, params = {}, successCallback = () => {}, errorCallback = () => {}) {
	apiRequest("groups.leave",{ ...params
	}, access_token, (response) => {
		successCallback(response)
	},(error) => {
		errorCallback(error)
	})
}

//Removes a user from the community.
//user
export function groupsRemoveUser(access_token, params = {}, successCallback = () => {}, errorCallback = () => {}) {
	apiRequest("groups.removeUser",{ ...params
	}, access_token, (response) => {
		successCallback(response)
	},(error) => {
		errorCallback(error)
	})
}

//Allows to reorder links in the community.
//user
export function groupsReorderLink(access_token, params = {}, successCallback = () => {}, errorCallback = () => {}) {
	apiRequest("groups.reorderLink",{ ...params
	}, access_token, (response) => {
		successCallback(response)
	},(error) => {
		errorCallback(error)
	})
}

//Returns a list of communities matching the search criteria.
//user
export function groupsSearch(access_token, params = {}, successCallback = () => {}, errorCallback = () => {}) {
	apiRequest("groups.search",{ ...params
	}, access_token, (response) => {
		successCallback(response)
	},(error) => {
		errorCallback(error)
	})
}

//Allow to set notifications settings for group.
//user,group
export function groupsSetCallbackSettings(access_token, params = {}, successCallback = () => {}, errorCallback = () => {}) {
	apiRequest("groups.setCallbackSettings",{ ...params
	}, access_token, (response) => {
		successCallback(response)
	},(error) => {
		errorCallback(error)
	})
}

//Sets Long Poll notification settings
//user,group
export function groupsSetLongPollSettings(access_token, params = {}, successCallback = () => {}, errorCallback = () => {}) {
	apiRequest("groups.setLongPollSettings",{ ...params
	}, access_token, (response) => {
		successCallback(response)
	},(error) => {
		errorCallback(error)
	})
}

//undefined
//user
export function groupsUnban(access_token, params = {}, successCallback = () => {}, errorCallback = () => {}) {
	apiRequest("groups.unban",{ ...params
	}, access_token, (response) => {
		successCallback(response)
	},(error) => {
		errorCallback(error)
	})
}
