import React from 'react';
import { Div, Button } from '@vkontakte/vkui';
import CellBlock from '../components/CellBlock/CellBlock';

class SubscribeBlock extends React.Component {
	render() {
		let { group, subscribeGroup } = this.props
		return (
			(group) ?
				<div>
					<CellBlock
						title={`${group.name}`}
						description={`${group.status}`}
						verified={group.verified}
						href={`https://vk.com/club${group.id}`}
						photo_50={group.photo_50}
					/>
					<Div className="pt-0">
						Подпишись на нашу группу и воспользуйся БЕСПЛАТНЫМ ботом, который анализирует, почему в твоей группе или на личной странице мало продаж! А еще он дает практические рекомендации по исправлению.
					</Div>
					<Div className="pt-0 flex_end">
						<Button onClick={subscribeGroup}>Подписаться</Button>
					</Div>
				</div>
			:
				null
		);
	}
}

export default SubscribeBlock;