import React from 'react';
import { Div } from '@vkontakte/vkui';
import PhotoGalery from '../components/PhotoGalery/PhotoGalery';
import NotifyBlock from '../components/NotifyBlock/NotifyBlock';
import { keyInObj } from '../func/utils';


class AttachmentsBlock extends React.Component {

	renderAttachments = (attachments) => {
		let outAttach = {}
		attachments.forEach((attach) => {
			//перебираем аттачи
			if (outAttach[attach.type]) {
				outAttach[attach.type].push(attach[attach.type])
			} else {
				outAttach[attach.type] = []
				outAttach[attach.type].push(attach[attach.type])
			}
		})

		const photo = keyInObj(outAttach, "photo")
		const audio = keyInObj(outAttach, "audio")
		const video = keyInObj(outAttach, "video")
		const doc = keyInObj(outAttach, "doc")
		const graffiti = keyInObj(outAttach, "graffiti")
		const link = keyInObj(outAttach, "link")
		const note = keyInObj(outAttach, "note")
		const app = keyInObj(outAttach, "app")
		const poll = keyInObj(outAttach, "poll")
		const page = keyInObj(outAttach, "page")
		const album = keyInObj(outAttach, "album")
		const photos_list = keyInObj(outAttach, "photos_list")
		const market = keyInObj(outAttach, "market")
		const market_album = keyInObj(outAttach, "market_album")
		const pretty_cards = keyInObj(outAttach, "pretty_cards")


		return [
			(photo) ? <PhotoGalery key="photo" photos={photo}/> : null, 
			(audio) ? <NotifyBlock key="audio" className="mt-5" type="info">Это вложение пока не подерживается</NotifyBlock> : null,
			(video) ? <NotifyBlock key="video" className="mt-5" type="info">Это вложение пока не подерживается</NotifyBlock> : null,
			(doc) ? <NotifyBlock key="doc" className="mt-5" type="info">Это вложение пока не подерживается</NotifyBlock> : null,
			(graffiti) ? <NotifyBlock key="graffiti" className="mt-5" type="info">Это вложение пока не подерживается</NotifyBlock> : null,
			(link) ? <NotifyBlock key="link" className="mt-5" type="info">Это вложение пока не подерживается</NotifyBlock> : null,
			(note) ? <NotifyBlock key="note" className="mt-5" type="info">Это вложение пока не подерживается</NotifyBlock> : null,
			(app) ? <NotifyBlock key="app" className="mt-5" type="info">Это вложение пока не подерживается</NotifyBlock> : null,
			(poll) ? <NotifyBlock key="poll" className="mt-5" type="info">Это вложение пока не подерживается</NotifyBlock> : null,
			(page) ? <NotifyBlock key="page" className="mt-5" type="info">Это вложение пока не подерживается</NotifyBlock> : null,
			(album) ? <NotifyBlock key="album" className="mt-5" type="info">Это вложение пока не подерживается</NotifyBlock> : null,
			(photos_list) ? <NotifyBlock key="photos_list" className="mt-5" type="info">Это вложение пока не подерживается</NotifyBlock> : null,
			(market) ? <NotifyBlock key="market" className="mt-5" type="info">Это вложение пока не подерживается</NotifyBlock> : null,
			(market_album) ? <NotifyBlock key="market_album" className="mt-5" type="info">Это вложение пока не подерживается</NotifyBlock> : null,
			(pretty_cards) ? <NotifyBlock key="pretty_cards" className="mt-5" type="info">Это вложение пока не подерживается</NotifyBlock> : null,
		]
	}

	render() {
		let { attachments } = this.props
		return (
			(attachments) ?
				<Div className="pt-0">{this.renderAttachments(attachments)}</Div>
			: null
		);
	}
}

export default AttachmentsBlock;