import connect from '@vkontakte/vk-connect';
import config from '../config';

function getNewRequestId() {
	return (Math.floor(Math.random() * Number.MAX_SAFE_INTEGER)).toString();
}

export function apiRequest(method, params = {}, accessToken = '', successCallback = undefined, errorCallback = undefined) {
	let requestId = getNewRequestId();
	if (successCallback !== undefined || errorCallback !== undefined) {
		let clb = function callback(e) {
			let vkEvent = e.detail;
			if (!vkEvent) {
				//console.error('invalid event', e);
				return;
			}

			let type = vkEvent['type'];
			let data = vkEvent['data'];

			let found = false;
			if ('VKWebAppCallAPIMethodResult' === type && data['request_id'] === requestId) {
				if (successCallback !== undefined) {
					successCallback(data['response']);
				}

				found = true;
			} else if ('VKWebAppCallAPIMethodFailed' === type && data['request_id'] === requestId) {
				if (errorCallback !== undefined) {
					errorCallback(data);
				}

				found = true;
			}

			if (found) {
				connect.unsubscribe(clb);
			}

		};

		connect.subscribe(clb);
	}

	params['access_token'] = accessToken;

	if (params['v'] === undefined) {
		params['v'] = config.api_version;
	}

	connect.send('VKWebAppCallAPIMethod', {
		'method': method,
		'params': params,
		'request_id': requestId
	});
}