import React from 'react';
import { Alert } from '@vkontakte/vkui';
import app from './app';

function showAlert(alert) {
	app.setState({
		popout: alert
	})
}

function closeAlert() {
	app.setState({
		popout: null
	})
}

export function showSubscribe(func) {
	showAlert(
		<Alert
			actions={[{
				title: 'Отмена',
				autoclose: true,
				style: 'cancel'
			},{
				title: 'Хорошо',
				autoclose: true,
				action: func
			}]}
			onClose={closeAlert}
		>
			<h2>Подпишись!</h2>
			<p>Текст для подписки.</p>
		</Alert>
	)
}

export function showBadWord(word) {
	showAlert(
		<Alert
			actions={[{
				title: 'Хорошо',
				autoclose: true,
			}]}
			onClose={closeAlert}
		>
			<h2>Ошибка!</h2>
			<p>Мы не хотим, чтобы приложением пользовались мошенники. Попробуйте не использовать слова: {`"${word}"`}</p>
		</Alert>
	)
}

export function showTrueFavorite() {
	showAlert(
		<Alert
			actions={[{
				title: 'Хорошо',
				autoclose: true,
			}]}
			onClose={closeAlert}
		>
			<h2>Отлично!</h2>
			<p>Комментарий добавлен в избранное</p>
		</Alert>
	)
}

export function showFalseFavorite() {
	showAlert(
		<Alert
			actions={[{
				title: 'Хорошо',
				autoclose: true,
			}]}
			onClose={closeAlert}
		>
			<h2>Ошибка</h2>
			<p>Комментарий уже есть в избранном</p>
		</Alert>
	)
}
