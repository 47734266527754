/* кнопка скрол ту топ */
import React from 'react';
import Icon24Back from '@vkontakte/icons/dist/24/back';
import './style.css';

class ScrollTopButton extends React.Component {
	render() {
		let { onClick, show } = this.props
		if (show) {
			return (
				<div className="ScrollTopButtonContainer">
					<Icon24Back onClick={onClick} fill="var(--placeholder_icon_foreground_primary)" className="ScrollTopButtonIcon24Back"/>
				</div>
			)			
		} else {
			return null
		}
	}
}

export default ScrollTopButton;