import config from '../config';
import app from './app';

export function startOnError() {
	if (process.env.NODE_ENV === config.errorEnv && config.prodError) {
		window.addEventListener('error', function (e) {
			e.preventDefault();
			let { type, error, message } = e;
			app.setState({
				error: {
					show: true,
					type: type,
					message: message,
					error: error
				},
			})
		});
	}
}