//Этот файл сгенерирован автоматически
import { apiRequest } from '../../vkApiRequest';


//Returns detailed information on users.
//user,group,service
export function usersGet(access_token, params = {}, successCallback = () => {}, errorCallback = () => {}) {
	apiRequest("users.get",{ ...params
	}, access_token, (response) => {
		successCallback(response)
	},(error) => {
		errorCallback(error)
	})
}

//Returns a list of IDs of followers of the user in question, sorted by date added, most recent first.
//user,service
export function usersGetFollowers(access_token, params = {}, successCallback = () => {}, errorCallback = () => {}) {
	apiRequest("users.getFollowers",{ ...params
	}, access_token, (response) => {
		successCallback(response)
	},(error) => {
		errorCallback(error)
	})
}

//Returns a list of IDs of users and communities followed by the user.
//user,service
export function usersGetSubscriptions(access_token, params = {}, successCallback = () => {}, errorCallback = () => {}) {
	apiRequest("users.getSubscriptions",{ ...params
	}, access_token, (response) => {
		successCallback(response)
	},(error) => {
		errorCallback(error)
	})
}

//Returns information whether a user installed the application.
//user
export function usersIsAppUser(access_token, params = {}, successCallback = () => {}, errorCallback = () => {}) {
	apiRequest("users.isAppUser",{ ...params
	}, access_token, (response) => {
		successCallback(response)
	},(error) => {
		errorCallback(error)
	})
}

//Reports (submits a complain about) a user.
//user
export function usersReport(access_token, params = {}, successCallback = () => {}, errorCallback = () => {}) {
	apiRequest("users.report",{ ...params
	}, access_token, (response) => {
		successCallback(response)
	},(error) => {
		errorCallback(error)
	})
}

//Returns a list of users matching the search criteria.
//user
export function usersSearch(access_token, params = {}, successCallback = () => {}, errorCallback = () => {}) {
	apiRequest("users.search",{ ...params
	}, access_token, (response) => {
		successCallback(response)
	},(error) => {
		errorCallback(error)
	})
}
