import React from 'react';
import { Div, Link } from '@vkontakte/vkui';
import CellBlock from '../components/CellBlock/CellBlock';
import TextBlock from '../components/TextBlock/TextBlock';
import AttachmentsBlock from './AttachmentsBlock';

import { keyInObj, dateToFromNowDaily } from '../func/utils';
import { findObj, isUser } from '../func/utils';

class UserBlock extends React.Component {
	render() {
		let { item, profiles } = this.props
		const attachments = keyInObj(item, "attachments") //массив
		const { from_id, owner_id, post_id, id } = item
		const profile = findObj(from_id, profiles)
		return (
			(profile) ?
				<div>
					<CellBlock
						title={(isUser(from_id)) ? `${profile.first_name} ${profile.last_name}` : `${profile.name}`}
						description={
							<div> 
								{dateToFromNowDaily(item.date)} к <Link target="_blank" rel="noopener noreferrer" href={`https://vk.com/wall${owner_id}_${post_id}?reply=${id}`}><b>записи</b></Link>
							</div>
						}
						verified={profile.verified}
						online={profile.online}
						online_mobile={profile.online_mobile}
						href={(isUser(from_id)) ? `https://vk.com/id${profile.id}` : `https://vk.com/club${profile.id}`}
						photo_50={profile.photo_50}
					/>
					<Div className="pt-0">
						<TextBlock showMoreButton={true} length={300}>{item.text}</TextBlock>
					</Div>

					<AttachmentsBlock
						attachments={attachments}
					/>
				</div>
			:
				null
		);
	}
}

export default UserBlock;