import React from 'react';
import { Panel, Spinner, Group, Div, Button } from '@vkontakte/vkui';
import Header from '../components/Header/Header';
import UserBlock from '../myComponents/UserBlock';
import ControlPanel from '../myComponents/ControlPanel';

import app from '../func/app';
import config from '../config';
import { isUser, getGidsUids } from '../func/utils';
import { getFavorites } from '../func/back';
import { getMissingUsers, getMissingGroups } from '../func/vk';

import bigStar from '../images/bigStar.png'

class HomeFavorite extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			isLoad: true,
			isLoadButton: true,
		};
	}

	get items() {
		let { items } = this.props
		return items
	}

	goNextPage = () => {
		this.setState({
			isLoadButton: false,
		}, () => {
			//тут мы делаем 
			const { navCount, navOffset } = this.props
			const newOffset = navCount + navOffset
			getFavorites(navCount, newOffset, (response) => {
				getMissingUsers(app.state.favorites.profiles, config.access_token, getGidsUids(response.items).uids, (uidsData) => {
					getMissingGroups(app.state.favorites.groups, config.access_token, getGidsUids(response.items).gids, (gidsData) => {
						app.setState({
							favorites: {
								...app.state.favorites,
								groups: (gidsData) ? [...gidsData, ...app.state.favorites.groups] : [...app.state.favorites.groups],
								items: [...app.state.favorites.items, ...response.items],
								profiles: (uidsData) ? [...uidsData, ...app.state.favorites.profiles] : [...app.state.favorites.profiles],
							},
							favPagination: {
								count: navCount,
								offset: newOffset,
							}
						}, () => {
							this.setState({
								isLoadButton: true,
							})
						})
					})
				})
			})
		})
	}

	render() {
		let { isLoad, isLoadButton } = this.state
		let { profiles, groups, count, navCount, navOffset } = this.props
		return (
			<Panel theme="white" id={this.props.id}>
				<Header name="Избранное"/>

				{
					(!isLoad) ? //данные получены
						<Spinner size="regular" style={{ marginTop: 20 }} />
					: 
						<div>
							{
								this.items.map((item, i) => {
									const { from_id, id } = item
									return (
										<Group key={`${item.id}${id}`}>
											<UserBlock profiles={isUser(from_id) ? profiles : groups} item={item} isFavorites={true}/>
											<ControlPanel 
												item={item}
												profiles={isUser(from_id) ? profiles : groups}
												search={false}
											/>
										</Group>
									)
								})
							}
							{
								(count > navCount + navOffset) ?
									(!isLoadButton) ? 
										<Spinner size="regular" style={{ marginTop: 15, marginBottom: 15 }} />
									:
										<Div className="pt-0 flex_center">
											<Button level="secondary" onClick={this.goNextPage}>Ещё</Button>
										</Div>
								: 
									(this.items.length === 0) ?
										<div>
											<Div className="flex_center">
												<img className="imgBig" alt="" src={bigStar}/>
											</Div>
											<Div style={{ color: "var(--field_text_placeholder)" }} className="flex_center">
												Вы пока не добавили ничего в избранное
											</Div>
										</div>
									: null
							}
						</div>
				}

			</Panel>
		)
	}
}

export default HomeFavorite;