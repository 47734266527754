import config from '../config';
import 'whatwg-fetch'

export function sentReq(json_data, callback = () => {}, callback_error = () => {}) {
	let url = config.backUrl
	json_data.params = config.params
	
	fetch(url, {
		method: 'post',
		headers: {
			'Accept': 'application/json, text/plain, */*',
			'Content-Type': 'application/x-www-form-urlencoded'
		},
		body: 'data=' + encodeURIComponent(JSON.stringify(json_data))
	})
	.then(function (response) {
		return response.json();
	})
	.then((data) => {
		callback(data);
	})
	.catch(function (error) {
		callback_error(error)
	});
}

export function sentWss(connection, json_data) {
	json_data.params = config.params
	connection.send(JSON.stringify(json_data));
}

export function getFavorites(count, offset, callback) {
	sentReq({command: "getFavorites", count: count, offset: offset}, (response) => {
		if (!response.error) {
			callback(response.response)
		} else {
			console.log(response.text_error)
		}
	}, (error) => {
		console.log(error)
	})
}

export function addFavorite(cid, from_id, reply_data, callback = () => {}) {
	sentReq({command: "addFavorite", cid: cid, from_id: from_id, reply_data: reply_data }, (response) => {
		if (!response.error) {
			callback(response.response)
		} else {
			console.log(response.text_error)
		}
	}, (error) => {
		console.log(error)
	})
}

export function delFavorite(cid, from_id, callback = () => {}) {
	sentReq({command: "delFavorite", cid: cid, from_id: from_id }, (response) => {
		if (!response.error) {
			callback(response.response)
		} else {
			console.log(response.text_error)
		}
	}, (error) => {
		console.log(error)
	})
}
