//Этот файл сгенерирован автоматически
import { apiRequest } from '../../vkApiRequest';


//Prevents news from specified users and communities from appearing in the current user's newsfeed.
//user
export function newsfeedAddBan(access_token, params = {}, successCallback = () => {}, errorCallback = () => {}) {
	apiRequest("newsfeed.addBan",{ ...params
	}, access_token, (response) => {
		successCallback(response)
	},(error) => {
		errorCallback(error)
	})
}

//Allows news from previously banned users and communities to be shown in the current user's newsfeed.
//user
export function newsfeedDeleteBan(access_token, params = {}, successCallback = () => {}, errorCallback = () => {}) {
	apiRequest("newsfeed.deleteBan",{ ...params
	}, access_token, (response) => {
		successCallback(response)
	},(error) => {
		errorCallback(error)
	})
}

//undefined
//user
export function newsfeedDeleteList(access_token, params = {}, successCallback = () => {}, errorCallback = () => {}) {
	apiRequest("newsfeed.deleteList",{ ...params
	}, access_token, (response) => {
		successCallback(response)
	},(error) => {
		errorCallback(error)
	})
}

//Returns data required to show newsfeed for the current user.
//user
export function newsfeedGet(access_token, params = {}, successCallback = () => {}, errorCallback = () => {}) {
	apiRequest("newsfeed.get",{ ...params
	}, access_token, (response) => {
		successCallback(response)
	},(error) => {
		errorCallback(error)
	})
}

//Returns a list of users and communities banned from the current user's newsfeed.
//user
export function newsfeedGetBanned(access_token, params = {}, successCallback = () => {}, errorCallback = () => {}) {
	apiRequest("newsfeed.getBanned",{ ...params
	}, access_token, (response) => {
		successCallback(response)
	},(error) => {
		errorCallback(error)
	})
}

//Returns a list of comments in the current user's newsfeed.
//user
export function newsfeedGetComments(access_token, params = {}, successCallback = () => {}, errorCallback = () => {}) {
	apiRequest("newsfeed.getComments",{ ...params
	}, access_token, (response) => {
		successCallback(response)
	},(error) => {
		errorCallback(error)
	})
}

//Returns a list of newsfeeds followed by the current user.
//user
export function newsfeedGetLists(access_token, params = {}, successCallback = () => {}, errorCallback = () => {}) {
	apiRequest("newsfeed.getLists",{ ...params
	}, access_token, (response) => {
		successCallback(response)
	},(error) => {
		errorCallback(error)
	})
}

//Returns a list of posts on user walls in which the current user is mentioned.
//user
export function newsfeedGetMentions(access_token, params = {}, successCallback = () => {}, errorCallback = () => {}) {
	apiRequest("newsfeed.getMentions",{ ...params
	}, access_token, (response) => {
		successCallback(response)
	},(error) => {
		errorCallback(error)
	})
}

//, Returns a list of newsfeeds recommended to the current user.
//user
export function newsfeedGetRecommended(access_token, params = {}, successCallback = () => {}, errorCallback = () => {}) {
	apiRequest("newsfeed.getRecommended",{ ...params
	}, access_token, (response) => {
		successCallback(response)
	},(error) => {
		errorCallback(error)
	})
}

//Returns communities and users that current user is suggested to follow.
//user
export function newsfeedGetSuggestedSources(access_token, params = {}, successCallback = () => {}, errorCallback = () => {}) {
	apiRequest("newsfeed.getSuggestedSources",{ ...params
	}, access_token, (response) => {
		successCallback(response)
	},(error) => {
		errorCallback(error)
	})
}

//Hides an item from the newsfeed.
//user
export function newsfeedIgnoreItem(access_token, params = {}, successCallback = () => {}, errorCallback = () => {}) {
	apiRequest("newsfeed.ignoreItem",{ ...params
	}, access_token, (response) => {
		successCallback(response)
	},(error) => {
		errorCallback(error)
	})
}

//Creates and edits user newsfeed lists
//user
export function newsfeedSaveList(access_token, params = {}, successCallback = () => {}, errorCallback = () => {}) {
	apiRequest("newsfeed.saveList",{ ...params
	}, access_token, (response) => {
		successCallback(response)
	},(error) => {
		errorCallback(error)
	})
}

//Returns search results by statuses.
//user,service
export function newsfeedSearch(access_token, params = {}, successCallback = () => {}, errorCallback = () => {}) {
	apiRequest("newsfeed.search",{ ...params
	}, access_token, (response) => {
		successCallback(response)
	},(error) => {
		errorCallback(error)
	})
}

//Returns a hidden item to the newsfeed.
//user
export function newsfeedUnignoreItem(access_token, params = {}, successCallback = () => {}, errorCallback = () => {}) {
	apiRequest("newsfeed.unignoreItem",{ ...params
	}, access_token, (response) => {
		successCallback(response)
	},(error) => {
		errorCallback(error)
	})
}

//Unsubscribes the current user from specified newsfeeds.
//user
export function newsfeedUnsubscribe(access_token, params = {}, successCallback = () => {}, errorCallback = () => {}) {
	apiRequest("newsfeed.unsubscribe",{ ...params
	}, access_token, (response) => {
		successCallback(response)
	},(error) => {
		errorCallback(error)
	})
}
