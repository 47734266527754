/* Ошибка с кнопкой перезагрузки страницы */
import React from 'react';
import { Div, Button } from '@vkontakte/vkui';

class WentWrong extends React.Component {
	render() {
		let { message } = this.props
		return (
			<div>
				<Div className="flex_center">
					{message}
				</Div>
				<Div className="flex_center pt-0">
					<div>
					{
						<Button 
							onClick={() => {
								window.location.reload()
							}}>
							Перезагрузить страницу
						</Button>
					}
					</div>
				</Div>
			</div>
		)
	}
}

export default WentWrong;