import config from '../config';

export function startLocalStorageСounter() {
	if (config.localStorageСounter) {
		const name = 'visit';
		//получаем данные 
		let visit = localStorage.getItem(name)
		if (visit) {
			visit = Number(visit) + 1
			localStorage.setItem(name, visit)
		} else {
			localStorage.setItem(name, 1)
		}
	}
}