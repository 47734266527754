/* Блок для вывода лайка */
import React from 'react';
import './style.css';
import Icon24Chevron from '@vkontakte/icons/dist/24/chevron';

class MenuIcon extends React.Component {
	render() {
		let { isOpen, onClick } = this.props
		return (isOpen) ? 
			<Icon24Chevron onClick={onClick} className="MenuIconOpen"/> 
			: 
			<Icon24Chevron onClick={onClick} className="MenuIcon"/> 
	}
}

export default MenuIcon;