/* Блок для вывода текста */
/*
show - определяет сворачаивать ли текст 
showMoreButton - показывать ли кнопку "Показать полностью"
length - длинны в символах для свёрнутого текста
*/
import React from 'react';
import { Link } from '@vkontakte/vkui';
import './style.css';

class TextBlock extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			show: (this.props.children.length <= this.props.length) ? true : false,
		};
	}

	parseLink = (text, pref, cfg = {}) => {
		// eslint-disable-next-line
		const regexp = /(\[[a-zA-Z\@\:\/\/\.0-9\-_]+\|.*?\]|<.*?>)/gmu
		let parts = text.split(regexp)

		if (parts.length === 1) {
			return parts[0]
		}
		let res = []
		parts.forEach( (t,i) => {
			if (t.indexOf('[') === 0 && !cfg['noLink']) {
				const linkPrefix = /(((https|http):\/\/)?(www\.)?)/
				let tag = t.split('|')
				let href = 'https://vk.com/' + tag[0].replace('[', '').replace(linkPrefix, '')
				let text = tag[1].replace(']', '')
				res.push(<Link href={href} target="_blank" rel="noopener noreferrer" key={pref + '_' + i}>{text}</Link>)
			} else if (t.indexOf('<link') === 0) {
				let tag = t.split('|')
				let href = tag[0].replace('<link', '')
				let text = tag[1].replace('link>', '')
				res.push(<a href={href} target="_blank" rel="noopener noreferrer" key={pref + '_' + i}>{text}</a>)
			} else if (t.indexOf('<') === 0 && !cfg['noStrong']) {
				res.push(<strong key={pref + '_' + i}>{t.substr(1, t.length-2)}</strong>)
			} else {
				res.push(t)
			}
		} )
		return res
	}

	linkify = (text) => {
		// eslint-disable-next-line
		const regExp = /(((http|https):\/\/)?(www\.)?[a-z0-9-]{2,}\.[a-z]{1}[a-z0-9-]{1,5}([^\s.,()]{1,})?)/gi;
		const textBlock = text.split(' ')
		const newArr = textBlock.map((item, i) => {
			return (regExp.test(item)) ? <Link href={(item.substr(0,8) !== 'https://' && item.substr(0,7) !== 'http://') ? `https://${item}` : item} target="_blank" rel="noopener noreferrer" key={`${(Math.floor(Math.random() * Number.MAX_SAFE_INTEGER)).toString()}${i}`}>{`${item}`}</Link> : `${item}`
		})
		let rrr = [],
			ttt = '';

		newArr.forEach((item) => {
			if (typeof item === 'string') {
				ttt = `${ttt} ${item}` 
			} else {
				rrr.push(`${ttt} `)
				ttt = ''
				rrr.push(item)
			}
		})
		rrr.push(ttt)
		return rrr
	}

	nToBr = (string, cfg = {}) => {
		string = string || ""
		if (!cfg['noTypography']) {
			string = string.replace(/&shy;/g, "\u00AD")
			string = string.replace(/&nbsp;/g, "\u00A0")
			string = string.replace(/&#8209;/g, "\u2011")
		}

		let stringArray = string.split('\n')
		let length = stringArray.length
		let result = []

		for (let i = 0; i < length; i++) {
			result.push(this.linkify(stringArray[i].replace(/\s+/g,' ').trim(), i, cfg))
			if (i !== length - 1) {
				result.push(<br key={i}/>)
			}
		}

		let newArrayTest = []
		result.forEach((array) => {
			if (Array.isArray(array)) {
				array.forEach((oneText) => {
					if (typeof oneText === 'string') {
						newArrayTest.push(this.parseLink(oneText, 1, 'vk_link'))
					} else {
						newArrayTest.push(oneText)
					}
				}) 
			} else {
				newArrayTest.push(array)
			}

		})
		return newArrayTest
	}


	getSmallText = (text, length) => {
		text = `${text.substr(0,length).trim()}...`
		return ( this.nToBr(text) )
	}

	showAllText = () => {
		this.setState({
			show: true,
		})
	}

	render() {
		let { children, length, showMoreButton } = this.props
		let { show } = this.state
		return (
			(show) ?
				<div>{this.nToBr(children)}</div>
			:
				<div>
					<div>{this.getSmallText(children, length)}</div>
					{
						(showMoreButton) ?
							<div className="TextBlockShowAll">
								<Link className="TextBlockShowAll_item" onClick={this.showAllText}><b>Показать полностью...</b></Link>
							</div>
						: null
					}
				</div>
		)
	}
}

export default TextBlock;