import app from './app';
import config from '../config';
import navigation from './navigation';
//import wss from './wss';
import { getFavorites } from './back';
import { getParams, getHash, getGidsUids } from './utils';
import { getUserInfo, getStartToken, listenVKWebAppUpdateConfig, getMissingUsers, getMissingGroups } from './vk';
import { groupsIsMember } from './generate/methods/groups';

export function go(callback) {

	listenVKWebAppUpdateConfig((data) => {
		let scheme = data.scheme ? data.scheme : 'client_light';
		app.setState({
			scheme: scheme
		})		
	})

	window.onpopstate = (e) => {
		navigation.nativeGoBack()
		return;
	}

	const params = getParams()
	const hash = getHash()

	config.set({
		params: params,
		hash: hash,
		app_id: +params.vk_app_id
	}, () => {
		//wss.init(() => { //если нужно конектится к сокету
			startApp()
		//}) 
	})
}

function startApp() {
	getUserInfo((user) => {
		getStartToken(config.params.vk_access_token_settings.split(','), (token) => {
			getFavorites(30, 0, (response) => { //первый запрос к беку
				//тут нужно получитьв се группы и пользователей и распихать их по массивам
				groupsIsMember((token) ? token.access_token : config.access_token, {
					group_id:config.isMemberGid,
					user_id:+config.params.vk_user_id
				}, (isMember) => {
					let gids = getGidsUids(response.items).gids
					gids.push(39599132)
					const uids = getGidsUids(response.items).uids
					getMissingUsers(app.state.favorites.profiles, (token) ? token.access_token : config.access_token, uids, (uidsData) => {
						getMissingGroups(app.state.favorites.groups, (token) ? token.access_token : config.access_token, gids, (gidsData) => {
							if (uidsData) {
								response.profiles = [...uidsData]
							}
							if (gidsData) {
								response.groups = [...gidsData]
							}
							setConfigAndState(token, response, user, isMember)
						})
					})
				})
			})
		})
	})
}


function setConfigAndState(token, firstAnswerBack, userData, isMember) {
	if (token) { //сценарий где токен получен
		config.set({access_token: token.access_token, userData: userData}, () => {
			app.setState({
				userScope: token.scope.split(','),
				isLoad: true,
				header: (config.params.vk_platform === "desktop_web" && config.desktopHeader) ? false : true, //показываем или скрываем хедер при кастомном хедере на десктопе 
				isStartInGroup: isStartInGroup(),
				goStartPanel: isStartPanel(),
				favorites: {
					...firstAnswerBack
				},
				isMember: (isMember) ? true : false
			})
		})
	} else { //сценарий где токен не получен
		config.set({userData: userData}, () => {
			app.setState({
				isLoad: true,
				header: (config.params.vk_platform === "desktop_web" && config.desktopHeader) ? false : true, //показываем или скрываем хедер при кастомном хедере на десктопе 
				isStartInGroup: isStartInGroup(),
				goStartPanel: isStartPanel(),
				favorites: {
					...firstAnswerBack
				},
				isMember: (isMember) ? true : false
			})
		})
	}
}

function isStartPanel() {
	//проверить localstorage
	if (isStartInGroup()) {
		return false
	} else {
		let welcome = localStorage.getItem('welcome')
		if (!welcome && config.startPanel) {
			return true
		} else {
			return false
		}
	}
}

function isStartInGroup() {
	return (config.params.vk_group_id) ? true : false
}