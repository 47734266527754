/* Информация о не поддерживаемой операционной системе */
import React from 'react';
import './style.css';

class BadVersion extends React.Component {
	render() {
		return (
			<div className="badVersion">
				<div className="badVersionItem">
					<img alt="" src="https://sun1-1.userapi.com/c816323/v816323312/22/nAj3QoK4dmo.png"/>
				</div>
				<div className="badVersionItem">
					К сожалению приложение не поддерживает вашу операционную систему.
				</div>
			</div>
		);
	}
}

export default BadVersion;
