import React from 'react';
import { Panel, Div, Button, Spinner, Group } from '@vkontakte/vkui';
import Header from '../components/Header/Header';
import ScrollTopButton from '../components/ScrollTopButton/ScrollTopButton';
import UserBlock from '../myComponents/UserBlock';
import ControlPanel from '../myComponents/ControlPanel';
import SubscribeBlock from '../myComponents/SubscribeBlock';
import SearchBlock from '../components/SearchBlock/SearchBlock';

import app from '../func/app';
import { showBadWord } from '../func/alert';
import config from '../config';
import { apiNewsfeedSearch, webAppJoinGroup } from '../func/vk';
import { isUser, findObjectById } from '../func/utils';
import { animateScroll as scroll } from 'react-scroll'

import bigSearch from '../images/bigSearch.png'

class HomeSearch extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			isLoad: true,
			isLoadButton: true,
		};
	}
	
	componentDidMount() {
		let { isMember } = this.props
		if (!isMember) {
			const visit = +localStorage.getItem('visit')
			const remainder = (visit % 10) / 100;
			if (remainder === 0) {
				setTimeout(() => {
					this.subscribeGroup()
				}, 10000);
			}
		}
	}

	onChange = (e) => {
		const { name, value } = e.currentTarget;
		app.setState({ 
			[name]: value,
			data: {
				groups: [],
				items: [],
				next_from: null,
				profiles: [],
				count: null,
				total_count: null
			}
		});
	}

	get items() {
		let { items } = this.props
		return items
	}

	search = () => {
		const { text, prefix, settings, textDomain } = this.props
		const test = config.blackWord.filter((word) => { return ( text.toLowerCase().indexOf(word.toLowerCase()) > -1 ) ? true : false })
		if (test.length > 0) {
			showBadWord(test[0])
		} else {
			this.setState({isLoad: false}, () => {
				const seachText =  `${prefix} ${(settings.domain) ? `domain:${textDomain}` : ``} ${(settings.has) ? settings.has : ``} ${text}`
				console.log(seachText)
				apiNewsfeedSearch(config.access_token, seachText, false, (response) => {
					app.setState({
						data: {
							...response
						},
					}, () => {
						this.setState({
							isLoad: true
						})
					})
				})
			})
		}
	}

	goNextPage = () => {

		const { text, prefix, settings, textDomain, next_from } = this.props
		this.setState({
			isLoadButton: false
		}, () => {
			const seachText =  `${prefix} ${(settings.domain) ? `domain:${textDomain}` : ``} ${(settings.has) ? settings.has : ``} ${text}`
			apiNewsfeedSearch(config.access_token, seachText, next_from, (response) => {
				app.setState({
					data: {
						groups: [...app.state.data.groups, ...response.groups],
						items: [...app.state.data.items, ...response.items],
						next_from: response.next_from,
						profiles: [...app.state.data.profiles, ...response.profiles],
						count: response.count,
						total_count: response.total_count
					},
				}, () => {
					this.setState({
						isLoadButton: true
					})
				})
			})
		})
	}

	subscribeGroup = () => {
		webAppJoinGroup(config.isMemberGid, (response) => {
			if (response.result) {
				app.setState({
					isMember: true,
				})
			}
		})
	}

	settingHasPhoto = (e) => {
		const { checked } = e.currentTarget;
		if (checked) {
			app.setState({
				settings:{
					...app.state.settings,
					has:'has:photo'
				},
				data: {
					groups: [],
					items: [],
					next_from: null,
					profiles: [],
					count: null,
					total_count: null
				}
			})
		} else {
			app.setState({
				settings:{
					...app.state.settings,
					has: false
				},
				data: {
					groups: [],
					items: [],
					next_from: null,
					profiles: [],
					count: null,
					total_count: null
				}
			})
		}
	}

	settingHasDomain = (e) => {
		const { checked } = e.currentTarget;
		if (checked) {
			app.setState({
				settings:{
					...app.state.settings,
					domain:true
				},
				data: {
					groups: [],
					items: [],
					next_from: null,
					profiles: [],
					count: null,
					total_count: null
				}
			})
		} else {
			app.setState({
				settings:{
					...app.state.settings,
					domain:false
				},
				textDomain: '',
				data: {
					groups: [],
					items: [],
					next_from: null,
					profiles: [],
					count: null,
					total_count: null
				}
			})
		}
	}

	onChangeDomain = (e) => {
		const { name, value } = e.currentTarget;
		app.setState({ 
			[name]: value,
			data: {
				groups: [],
				items: [],
				next_from: null,
				profiles: [],
				count: null,
				total_count: null
			}
		});		
	}

	scrollToTop = () => {
		scroll.scrollToTop({
			duration: 200,
			delay: 0,
		});
	}

	render() {
		let { isLoad, isLoadButton } = this.state
		let { text, count, profiles, groups, isMember, favGroups, settings, textDomain, next_from } = this.props
		return (
			<Panel theme="white" id={this.props.id}>
				<Header name="Поиск"/>
				
				<SearchBlock
					top="Текст поиска"
					placeholder="Например: SMM"
					onChange={this.onChange}
					text={text}
					search={this.search}
					settingHasPhoto={this.settingHasPhoto}
					settingHasDomain={this.settingHasDomain}
					onChangeDomain={this.onChangeDomain}
					textDomain={textDomain}
					settings={settings}
				/>

				{
					(!isLoad) ? //данные получены
						<Spinner size="regular" style={{ marginTop: 20 }} />
					: 
						<div>
							{
								(this.items.length > 10) ?
									<ScrollTopButton
										show={true}
										onClick={this.scrollToTop}
									/>
								: null
							}

							{
								(!isMember & this.items.length > 0) ?
									<SubscribeBlock
										subscribeGroup={this.subscribeGroup}
										group={findObjectById(favGroups, +config.isMemberGid)}
									/>
								: null
							}
							{
								this.items.map((item, i) => {
									const { from_id, id } = item
									return (
										<Group key={`${item.id}${id}`}>
											<UserBlock profiles={isUser(from_id) ? profiles : groups} item={item} isFavorites={true}/>
											<ControlPanel 
												item={item}
												profiles={isUser(from_id) ? profiles : groups}
												search={true}
											/>
										</Group>
									)
								})
							}
							{
								(this.items.length > 0 && count > this.items.length && next_from) ? 
									(!isLoadButton) ? 
										<Spinner size="regular" style={{ marginTop: 15, marginBottom: 15 }} />
									: 
										<Div className="pt-0 flex_center">
											<Button level="secondary" onClick={this.goNextPage}>Ещё</Button>
										</Div>
								: 
									//проверка на то, что сейчас вообще пусто в выдаче
									(this.items.length === 0) ?
										<div>
											<Div className="flex_center">
												<img className="imgBig" alt="" src={bigSearch}/>
											</Div>
											<Div style={{ color: "var(--field_text_placeholder)" }} className="flex_center">
												Тут будут результаты поиска...
											</Div>
										</div>
									: null
							}
						</div>
				}

			</Panel>
		)
	}
}

export default HomeSearch;