import React from 'react';
import { Div, CellButton } from '@vkontakte/vkui';
import MenuIcon from '../iconComponents/MenuIcon/MenuIcon';

import { addFavorite, delFavorite } from '../func/back';
import { showTrueFavorite, showFalseFavorite } from '../func/alert';
import { isUser, findObj, findObjId } from '../func/utils';
import app from '../func/app';

import Icon24FavoriteOutline from '@vkontakte/icons/dist/24/favorite_outline';
import Icon24Cancel from '@vkontakte/icons/dist/24/cancel';

class ControlPanel extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			isOpen: false,
			loadFavorite: true,
		};
	}

	openMenu = () => {
		this.setState({
			isOpen: (!this.state.isOpen)
		})
	}

	addToFavorites = () => {
		const { item, profiles } = this.props
		const { id, from_id } = item
		this.setState({
			loadFavorite: false
		}, () => {
			addFavorite(id, from_id, item, (response) => {
				if (response.status) {
					//пост добавлен
					const { favorites } = app.state
					const profile = findObj(from_id, profiles)
					if (isUser(from_id)) {
						app.setState({
							favorites:{
								...favorites,
								items: [item, ...favorites.items],
								profiles: [profile, ...favorites.profiles],
								//count: favorites.count + 1
							}
						}, () => {
							this.setState({
								loadFavorite: true
							}, () => {
								showTrueFavorite()
							})
						})
					} else {
						app.setState({
							favorites:{
								...favorites,
								items: [item, ...favorites.items],
								groups: [profile, ...favorites.groups],
								//count: favorites.count + 1
							}
						}, () => {
							this.setState({
								loadFavorite: true
							}, () => {
								showTrueFavorite()
							})
						})
					}
				} else {
					//пост уже есть в избранном
					this.setState({
						loadFavorite: true
					}, () => {
						showFalseFavorite()
					})
				}
			})			
		})
	}

	delToFavorites = () => {
		const { item } = this.props
		const { id, from_id } = item
		delFavorite(id, from_id, (response) => {
			//найти итем
			const { favorites } = app.state
			const index = findObjId(id, from_id, favorites.items)
			app.setState({
				favorites: {
					...favorites,
					items: [...favorites.items.slice(0, index), ...favorites.items.slice(index+1)],
					//count: favorites.count - 1
				}
			})
		})
	}

	render() {
		let { isOpen, loadFavorite } = this.state
		let { search } = this.props
		return (
			<div>
				<Div className={`flex_end pt-0 ${(isOpen) ? "pb-0" : ""}`}>
					<MenuIcon onClick={this.openMenu} isOpen={isOpen}/>
				</Div>
				{
					(isOpen) ?
						(search) ?
							<div>
								<CellButton disabled={!loadFavorite} onClick={this.addToFavorites} before={<Icon24FavoriteOutline />}>Добавить в избранное</CellButton>
							</div>
						: 
							<div>
								<CellButton disabled={!loadFavorite} onClick={this.delToFavorites} before={<Icon24Cancel />}>Удалить из избранного</CellButton>
							</div>
					: null
				}
			</div>
		);
	}
}

export default ControlPanel;