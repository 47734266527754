import React from 'react';
import { Panel } from '@vkontakte/vkui';
import WentWrong from '../../components/WentWrong/WentWrong';
import './style.css';

class OnError extends React.Component {
	render() {
		let { id, error } = this.props
		console.log(error)
		return (
			<Panel id={id}>
				<WentWrong
					message="Похоже, что-то пошло не так..."
				/>
			</Panel>
		)
	}
}

export default OnError;