import React from 'react';
import { Group, FormLayout, FormLayoutGroup, Input, Div, Button, CellButton, Checkbox } from '@vkontakte/vkui';
import Icon24BrowserForward from '@vkontakte/icons/dist/24/browser_forward';

import './style.css';

class SearchBlock extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			isOpen: false,
		};
	}

	componentDidMount() {
		document.onkeyup = (e) => {
			e = e || window.event;
			if (e.keyCode === 13) {
				let { search, text, textDomain, settings } = this.props
				let disabledSearch = (text.length > 0) ? 
					(settings.domain & textDomain.length <= 0) ? true : false
				: 
					(settings.domain & textDomain.length > 0 ) ? false : true 

				if (!disabledSearch) {
					search()
				}
			}
			// Отменяем действие браузера
			return false;
		}
	}

	tochMenu = () => {
		this.setState({
			isOpen: !this.state.isOpen
		})
	}

	render() {
		let { isOpen } = this.state
		let { onChange, text, placeholder, search, settingHasPhoto, settingHasDomain, onChangeDomain, settings, textDomain } = this.props
		let disabledSearch = (text.length > 0) ? 
			(settings.domain & textDomain.length <= 0) ? true : false
		: 
			(settings.domain & textDomain.length > 0 ) ? false : true 
		return (
			<Group>
				<FormLayout>
					<FormLayoutGroup top="Введите строку для поиска">
						<Input autoComplete="off" onChange={onChange} value={text} name="text" type="text" placeholder={placeholder}/>
					</FormLayoutGroup>
				</FormLayout>
				<Div className="pt-0">
					<CellButton 
						onClick={this.tochMenu}
						before={<Icon24BrowserForward className={(isOpen) ? `SearchBlockIcon24Browser_open` : `SearchBlockIcon24Browser_close` }/>}
					>
						Настройки
					</CellButton>
					{
						(isOpen) ?
							<div>
								<Checkbox checked={(settings.has) ? true : false} onChange={settingHasPhoto}>Искать только с фото</Checkbox>
								<Checkbox checked={(settings.domain) ? true : false} onChange={settingHasDomain}>Искать с доменом</Checkbox>
								{
									(settings.domain) ?
									<FormLayout>
										<FormLayoutGroup top="Введите домен">
											<Input name="textDomain" value={textDomain} onChange={onChangeDomain} type="text" placeholder="Например: google.com" />
										</FormLayoutGroup>
									</FormLayout>
									: null
								}
							</div>
						: null
					}
				</Div>
				<Div className="pt-0 flex_end">
					<Button disabled={disabledSearch} onClick={search}>Найти</Button>
				</Div>
			</Group>
		);
	}
}

export default SearchBlock;