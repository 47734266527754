/* Хедер приложений */
import React from 'react';
import { PanelHeader, HeaderButton, platform, IOS } from '@vkontakte/vkui';

import Icon28ChevronBack from '@vkontakte/icons/dist/28/chevron_back';
import Icon24Back from '@vkontakte/icons/dist/24/back';
import config from '../../config';

import './style.css';

const osname = platform();

class Header extends React.Component {

	renderMobileHeader = () => {
		let {panelBack, noShadow, name, theme} = this.props
		if (osname === IOS) {
			return (
				<PanelHeader 
					theme={theme}
					noShadow={noShadow}
					left={
							(panelBack) ?
								<HeaderButton onClick={panelBack}> 
									<Icon28ChevronBack />  
								</HeaderButton>
							: null
						}
				>
					{name}
				</PanelHeader>
			)
		} else {
			return (
				<PanelHeader 
					theme={theme}
					noShadow={noShadow}
					left={
							(panelBack) ?
								<HeaderButton onClick={panelBack}> 
									<Icon24Back /> 
								</HeaderButton>
							: null
						}
				>
					{name}
				</PanelHeader>
			)
		}
	}
	
	renderDesktopHeader = () => {
		let {panelBack} = this.props
		return ( //не показываем на десктопе панель хедера вообще
			(panelBack) ?
				<div className="DesktopHeader flex_start">
					<div onClick={panelBack} className="leftButton flex_start">
						<div className="pl-10">
							<Icon28ChevronBack />
						</div>
						<div className="pl-5">
							Назад
						</div>
					</div>
				</div>
			: null
		)
	}

	render() {
		let { params, desktopHeader } = config
		if (params.vk_platform === "desktop_web" && desktopHeader) {
			return this.renderDesktopHeader()
		} else {
			return this.renderMobileHeader()
		}
	}

}

export default Header;
